import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Badge } from "@ryerson/frontend.notification";
import { ButtonLink, Link } from "@ryerson/frontend.navigation";
import { ContentfulMetalCapability } from "../Types/Types";
import { companyToCompany } from "@components/Shared/model/Contentful";

export type StaticCapabilitiesContent = {
	title: string;
	navLabel: string;
	actionLabel: string | string[];
	actionUrl: string;
	badgeText: string;
	badgeColor: string;
};

export type DynamicCapabilitiesContent = {
	capabilities: ContentfulMetalCapability[];
};

export type CapabilitiesProps = {
	staticContent: StaticCapabilitiesContent;
	dynamicContent: DynamicCapabilitiesContent;
};

const Divider = styled.hr`
	display: block;
	width: 100%;
	margin-top: 38px;
	opacity: 0.1;
	margin-bottom: 38px;
`;

const SolutionItem = styled.div`
	display: block;
	width: 100%;
	height: 180px;
`;

const SolutionImage = styled.div`
	display: inline-block;
	width: 300px;
	margin-right: 100px;
	vertical-align: top;
	height: 180px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const SolutionTitle = styled.div`
	display: inline-block;
	width: 260px;
	vertical-align: top;
	position: relative;
	height: 180px;
	margin-right: 140px;
`;

const SolutionTitleBadge = styled.div`
	display: block;
	width: 100%;
	left: 0;
	bottom: 0;
	height: 30px;
	position: absolute;
`;

const SolutionBlurb = styled.div`
	display: inline-block;
	width: calc(100% - 800px);
	padding-top: 5px;
	box-sizing: border-box;
	height: 180px;
`;

const ButtonLinkContainer = styled.div`
	display: block;
	width: 100%;
	margin-top: 24px;
	height: auto;
	padding-bottom: 80px;
`;

const MobileSolutionItem = styled.div`
	display: block;
	width: 100%;
	margin-bottom: 40px;
	height: auto;
`;

const MobileSolutionItemImage = styled.div`
	display: block;
	width: 100%;
	position: relative;
	height: 180px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const MobileSolutionTitleBadge = styled.div`
	display: block;
	width: 100%;
	top: 20px;
	left: 20px;
	height: 24px;
	width: calc(100% - 40px);
	position: absolute;
`;

const MetalCapabilitiesComponent: React.FC<CapabilitiesProps> = ({
	staticContent,
	dynamicContent,
}) => {
	const company = companyToCompany(process.env.GATSBY_COMPANY ? process.env.GATSBY_COMPANY : "");
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="primary" vPadding="60px">
					<Typography
						variant="h1"
						type="primary"
						css={css`
							margin-bottom: 20px;
						`}
					>
						{staticContent.title}
					</Typography>
					{dynamicContent.capabilities
						.filter((capability: ContentfulMetalCapability) =>
							capability.company.includes(company)
						)
						.map((capability: ContentfulMetalCapability, index: number) => {
							return (
								<React.Fragment key={index}>
									<Divider
										css={css`
											color: ${theme.colors.primary.darkerGray};
										`}
									/>
									<SolutionItem>
										<SolutionImage
											css={css`
												background-image: url(${capability.image.file.url});
											`}
										/>
										<SolutionTitle>
											<Link to={capability.link}>
												<Typography
													variant="h3"
													css={css`
														letter-spacing: -2px;
													`}
												>
													{capability.title}
												</Typography>
											</Link>
											<SolutionTitleBadge>
												<Badge
													type="primary"
													color={
														staticContent.badgeColor &&
														staticContent.badgeColor.length > 0 &&
														staticContent.badgeColor === "red"
															? theme.colors.secondary.red
															: theme.colors.secondary.yellow
													}
												>
													{staticContent.badgeText}
												</Badge>
											</SolutionTitleBadge>
										</SolutionTitle>
										<SolutionBlurb>
											<Typography variant="div" type="primary" size="md">
												{capability.description.description}
											</Typography>
										</SolutionBlurb>
									</SolutionItem>
								</React.Fragment>
							);
						})}
					<Divider
						css={css`
							color: ${theme.colors.primary.darkerGray};
						`}
					/>
					<ButtonLinkContainer>
						<ButtonLink
							type="primary"
							to={staticContent.actionUrl}
							label={staticContent.actionLabel}
							orientation="left"
							gatsby={true}
						/>
					</ButtonLinkContainer>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" hPadding="0" vPadding="38px">
					{dynamicContent.capabilities
						.filter((capability: ContentfulMetalCapability) =>
							capability.company.includes(company)
						)
						.map((capability: ContentfulMetalCapability, index: number) => {
							return (
								<MobileSolutionItem key={index}>
									<MobileSolutionItemImage
										css={css`
											background-image: url(${capability.image.file.url});
										`}
									>
										<MobileSolutionTitleBadge>
											<Badge
												type="primary"
												size="sm"
												color={
													staticContent.badgeColor &&
													staticContent.badgeColor.length > 0 &&
													staticContent.badgeColor === "red"
														? theme.colors.secondary.red
														: theme.colors.secondary.yellow
												}
											>
												{staticContent.badgeText}
											</Badge>
										</MobileSolutionTitleBadge>
									</MobileSolutionItemImage>
									<Link to={capability.link}>
										<Typography
											variant="h3"
											type="primary"
											css={css`
												margin-top: 25px;
												margin-bottom: 16px;
											`}
										>
											{capability.title}
										</Typography>
									</Link>
									<Typography variant="div" size="md" type="primary">
										{capability.description.description}
									</Typography>
								</MobileSolutionItem>
							);
						})}
					<ButtonLinkContainer>
						<ButtonLink
							label={staticContent.actionLabel}
							to={staticContent.actionUrl}
							gatsby={true}
							type="primary"
							orientation="left"
						/>
					</ButtonLinkContainer>
				</ContentSection>
			</Media>
		</>
	);
};

export default MetalCapabilitiesComponent;
